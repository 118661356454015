.body {
    width: 100%;
    height: fit-content;
    padding: 40px;
  }
  
  .container {
    width: 100%;
    height: fit-content;
    padding: 20px;
    border: 5px double;
    position: relative;
  }
  
  .content_position {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  
  .gotLogo {
    height: 95px;
    width: 95px;
    margin: 5px auto;
  }
  
  .pdfHeader {
    text-align: center;
    margin: 5px 0px;
  }
  
  .pdfHeader h2 {
    font-weight: 700;
    font-size: 22px;
  }
  
  .timeline {
    display: flex;
    justify-content: space-between;
    margin: 20px 0px;
    font-weight: 700;
  }
  
  .mainText {
    text-align: justify;
  }
  
  /*******   information table   ********/
  .infoTable {
    width: 100%;
    margin: 20px 0;
    border: 1.5px solid black;
    border-right: 0px solid black;
  }
  
  .grid_container {
    text-align: center;
    display: grid;
    border-right: 1.5px solid black;
    grid-template-columns: repeat(7, 1fr);
  }
  
  .grid_container h5 {
    font-weight: 700;
    padding: 2px 0px;
  }
  
  .grid_container p {
    border-right: 1.5px solid black;
    border-top: 1.5px solid black;
    margin-bottom: -5px;
    padding: 2px 0px;
  }
  
  .grid_container p:nth-child(5) {
    border-right: 0px solid black;
  }
  
  .span_2 {
    grid-column: span 2;
  }
  
  .main {
    min-height: 495px;
  }
  
  .qrCode {
    max-width: 110px;
    height: 50px;
    width: 100%;
  }
  
  
  
  
  .pdfHeader {
    text-align: center;
    margin: 5px 0px;
  }
  
  .pdfHeader h2 {
    font-weight: 700;
    font-size: 22px;
  }
  
  .timeline {
    display: flex;
    justify-content: space-between;
    margin: 20px 0px;
    font-weight: 700;
  }
  
  .mainText {
    text-align: justify;
  }
  
  /*******   information table   ********/
  .infoTable {
    width: 100%;
    margin: 20px 0;
    border: 1.5px solid black;
    border-right: 0px solid black;
  }
  
  .grid_container {
    text-align: center;
    display: grid;
    border-right: 1.5px solid black;
    grid-template-columns: repeat(7, 1fr);
  }
  
  .grid_container h3 {
    font-weight: 700;
    padding: 2px 0px;
  }
  
  .grid_container p {
    border-right: 1.5px solid black;
    border-top: 1.5px solid black;
    padding: 2px 0px;
  }
  
  .grid_container p:nth-child(5) {
    border-right: 0px solid black;
  }
  
  .span_2 {
    grid-column: span 2;
  }
  
  .main {
    min-height: 492px;
  }
  
  .qrCode {
    max-width: 110px;
    height: 50px;
    width: 100%;
  }
  
  .upSign {
    text-align: end;
  }
  
  .upName {
    font-weight: 700;
    border-top: 1px dotted black;
    display: inline-block;
    margin-bottom: -20px;
  }
  
  .provLink {
    text-align: center;
    display: block;
    margin-top: 20px;
  }
  
  .upSign {
    text-align: end;
  }
  
  .upName {
    font-weight: 700;
    border-top: 1px dotted black;
    display: inline-block;
    margin-bottom: -20px;
  }
  
  .provLink {
    text-align: center;
    display: block;
    margin-top: 20px;
  }
  