footer{
  margin-top: 10px;
}
.qrAndUpSign {
  display: flex;
  justify-content: space-between;
}

.qrAndUpSignMargin_top {
  display: flex;
  justify-content: space-between;
}

.qrCode {
  max-width: 110px;
  height: 50px;
}
.qrcodeSection{
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 20px;
}
.upSign {
  margin-top: -45px;
  margin-bottom: 45px;
  text-align: end;
}

.upName {
  font-weight: 700;
  border-top: 1px dotted black;
  display: inline-block;
}
.find_sonod_infomation{
  display: flex;
  flex-direction: column;
  line-height: 1.3;
  font-size: 12px;
  color: black !important;
}