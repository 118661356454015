.Signcontainer {
  max-width: 1200px;
}
.Sinput {
  outline: none;
  border-radius: 4px;
  border: 1px solid #000;
  width: 100%;
  padding: 8px;
}
.row {
  margin-bottom: 20px;
}
.Slabel {
  display: block;
  margin-bottom: 4px;
  font-size: 13px;
  font-weight: 500;
}
.title {
  padding: 8px;
  font-size: 20px;
  margin-bottom: 25px;
  text-align: center;
  border-radius: 7px;
  color: white;
  background: #29a56c;
  font-weight: 500;
}
.warning {
  color: red;
  font-size: 11px;
  margin-top: 7px;
}
.rightTitle {
  background: #d8f1e5;
  font-size: 17px;
  padding: 7px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 12px;
}
.list {
  font-size: 50px;
}
.rightItems p {
  font-size: 13px;
}
.reqStart{
  color: red;
  font-size: larger;
  
}