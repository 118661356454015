.body {
  width: 100%;
  height: fit-content;
  padding: 40px;
}

.container {
  width: 100%;
  height: fit-content;
  padding: 20px;
  border: 5px double;
  overflow: hidden;
  position: relative;
}

.content_position {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.gotLogo {
  height: 70px;
  width: 70px;
}

.pdfHeader {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  text-align: center;
  margin: 5px 0px;
}

.title {
  grid-column: span 5;
}

.userImageDiv,
.blankDiv {
  grid-column: span 3;
  text-align: start;
}

.pdfHeader h2 {
  font-weight: 700;
  font-size: 22px;
}

.userImage_feram {
  border: double;
  width: 150px;
  height: 150px;
  margin-top: -5px;
}

.userImage {
  border: double;
  max-width: 150px;
  max-height: 215px;
  margin-top: -5px;
}

.timeline {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  align-items: center;
  margin: 0px 0px;
  font-weight: 700;
}

.timeline h2 {
  font-weight: 700;
  font-size: 20px;
  grid-column: span 5;
  display: inline;

  text-align: center;
}

.timeline h2 span {
  padding: 3px 8px;
  border: 2px solid rgb(160, 160, 160);
}

.timeline h6 {
  grid-column: span 3;
  text-align: start;
}

.mainText {
  text-align: justify;
}

.userInfo {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.userInfo h5 {
  font-weight: bold;
}

.userInfo p {
  grid-column: span 2;
}

.main {
  height: 550px;
  margin-top: 10px;
}

.infoTable {
  width: 100%;
  border: 1.5px solid black;
  border-right: 0px solid black;
}

.grid_container {
  text-align: center;
  display: grid;
  color: black;
  border-right: 1.5px solid black;
  border-top: 1.5px solid black;
  grid-template-columns: repeat(9, 1fr);
}

.grid_container h6 {
  font-weight: 700;
  padding: 2px 0px;
  border-right: 1.5px solid black;
}

.grid_container p {
  border-right: 1.5px solid black;
  margin: 0;
  padding: 2px 0px;
}

.grid_container:nth-child(1) {
  border-top: 0px solid black;
}

.grid_container p:nth-child(7) {
  border-right: 0px;
}

.grid_container h6:nth-child(7) {
  border-right: 0px;
}

.span_2 {
  grid-column: span 2;
  margin: 0;
}

.sum {
  grid-column: span 8;
  text-align: start;
  border-right: 0px solid black;
}

.qrAndUpSign {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.qrCode {
  max-width: 80px;
  height: 50px;
  width: 80%;
  margin-top: -20px;
}

.upSign {
  text-align: end;
}

.upName {
  font-weight: 700;
  border-top: 1px dotted black;
  display: inline-block;
}

.provLink {
  text-align: center;
  display: block;
  margin-top: 10px;
}

@media print {
  .body {
    height: 100vh;
    overflow: hidden;
  }

  .container {
    height: calc(100vh - 80px);
  }

  .content_position {
    height: calc(100vh - 130px);
  }
}