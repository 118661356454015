.pragraph1 {
  padding-left: 5pt;
  text-indent: 0pt;
  line-height: 20pt;
  text-align: left;
}
.pragraph2 {
  padding-left: 5pt;
  text-indent: 0pt;
  line-height: 17pt;
  text-align: left;
}
.pragraph3 {
  padding-left: 5pt;
  text-indent: 0pt;
  line-height: 15pt;
  text-align: left;
}
.pragraph4 {
  padding-left: 5pt;
  text-indent: 0pt;
  line-height: 12pt;
  text-align: left;
}
.pragraph5 {
  padding-left: 5pt;
  padding-right: 31pt;
  text-indent: 0pt;
  text-align: left;
}
.pragraph6 {
  padding-top: 5pt;
  padding-left: 61pt;
  text-indent: 0pt;
  text-align: center;
}
.pragraph7 {
  padding-top: 9pt;
  padding-left: 50pt;
  text-indent: 0pt;
  text-align: center;
}
.tableData1 {
  width: 140pt;
  border-top-style: solid;
  border-top-width: 1pt;
  border-left-style: solid;
  border-left-width: 1pt;
  border-bottom-style: solid;
  border-bottom-width: 1pt;
  border-right-style: solid;
  border-right-width: 1pt;
}
.tableData2 {
  width: 161pt;
  border-top-style: solid;
  border-top-width: 1pt;
  border-left-style: solid;
  border-left-width: 1pt;
  border-bottom-style: solid;
  border-bottom-width: 1pt;
  border-right-style: solid;
  border-right-width: 1pt;
}
.tableData3 {
  width: 160pt;
  border-top-style: solid;
  border-top-width: 1pt;
  border-left-style: solid;
  border-left-width: 1pt;
  border-bottom-style: solid;
  border-bottom-width: 1pt;
  border-right-style: solid;
  border-right-width: 1pt;
}
.tableData4 {
  width: 165pt;
  border-top-style: solid;
  border-top-width: 1pt;
  border-left-style: solid;
  border-left-width: 1pt;
  border-bottom-style: solid;
  border-bottom-width: 1pt;
  border-right-style: solid;
  border-right-width: 1pt;
}




