.body {
    width:100%;
    height: fit-content;
    padding: 20px;
    color: black;

}

.container {
    width: 756px;
    margin: auto;
    height: 1080px;
    overflow: hidden;
    position: relative;
    border: 3px solid rgb(209, 209, 209);
    padding: 20px 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.header_section {
    text-align: center;
}
.header_election{
    width: 80px;
}
.header_title {
    font-weight: 500;
    color: red;
    font-size: 30px;
    text-align: center;
    margin-bottom:5px;
}

.header_sub {
    color: black;
    font-size: 16px;
    text-align: center;
}

.header_profileImg {
    width: 150px;

}
.header_img_qrCode {
    margin:20px 0;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 60px;
    margin-left: 106px;
}
.header_img_qrCode img{
    width: 120px;
    height: 120px;
}


table {
    width: 100%;
    border-collapse: collapse;
}

table,
th,
td {
    border: 1px solid gray;
    padding: 1px 5px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.877);
}

td{
    font-size: 16px;
}
.newTest{
    text-align: center;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
        
}
.signatureText{
    margin-bottom: 10px;
    text-align: center;
    font-size: 13px;
    color: red;

}
.footer_image{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.footer_image img{
    width: 120px;
    opacity: 0.2;
}