.tableData {
    width: 250pt;
    border-top-style: solid;
    border-top-width: 1pt;
    border-left-style: solid;
    border-left-width: 1pt;
    border-bottom-style: solid;
    border-bottom-width: 1pt;
    border-right-style: solid;
    border-right-width: 1pt;
  }
  .pragraph {
    padding-top: 5pt;
    text-indent: 0pt;
    text-align: center;
  }