.body {
    width: 100%;
    height: fit-content;
    padding: 40px;
  }
  
  .container {
    width: 100%;
    height: fit-content;
    padding: 40px;
    border: 5px double;
    overflow: hidden;
    position: relative;
  }
  
  .content_position {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .gotLogo {
    height: 95px;
    width: 95px;
    margin: 5px auto;
  }
  
  .pdfHeader {
    text-align: center;
    margin: 5px 0px;
  }
  
  .pdfHeader h2 {
    font-weight: 700;
    font-size: 22px;
  }
  
  .timeline {
    display: flex;
    justify-content: space-between;
    margin: 20px 0px;
    font-weight: 700;
  }
  
  .mainText {
    font-size: 18px;
    text-align: justify;
  }
  
  /*******   information table   ********/
  .infoTable {
    width: 100%;
    margin: 20px 0;
    border: 1.5px solid black;
    border-right: 0px solid black;
  }
  
  .grid_container {
    text-align: center;
    display: grid;
    border-right: 1.5px solid black;
    grid-template-columns: repeat(7, 1fr);
  }
  
  .grid_container h3 {
    font-weight: 700;
    padding: 2px 0px;
  }
  
  .grid_container p {
    border-right: 1.5px solid black;
    border-top: 1.5px solid black;
    padding: 2px 0px;
  }
  
  .grid_container p:nth-child(5) {
    border-right: 0px solid black;
  }
  
  .span_2 {
    grid-column: span 2;
  }
  
  @media print {
    body {
      height: 100vh;
    }
    .container  {
      height: calc(100vh - 80px);
    }
    .content_position {
      height: calc(100vh - 160px);  
    }
  }
  