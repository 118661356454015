/* holding tax download */
.holdingTaxdownloadMain_styles {
    border: double 4px;
    padding: 10px;
    display: none;
}

/* holdin tax header  */
.holdintax_header {
    text-align: center;
}

.holdintax_header h1 {
    margin: 0;
    padding: 0;
}

/* table  */
#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#customers td,
#customers th {
    border: 1px solid #ddd;
    padding: 8px;
}

#customers tr:nth-child(even) {
    background-color: #f2f2f2;
}

#customers tr:hover {
    background-color: #ddd;
}

#customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04AA6D;
    color: white;
}

@media print {
    .holdingTaxdownloadMain_styles {
        display: block;
        /* Display when printing */
    }
}

.print-mode {
    display: block !important;
    /* Display when in print mode */
}