/* footer.css */

/* Container styles */
.footer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  background-color: #d8f1e5;
  /* Replace with your desired background color */
  color: #000000;
  /* Replace with your desired text color */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
}

/* Logo styles */
.footer img {
  width: 8rem;
  /* Adjust the width as needed */
}

/* Copyright text styles */
.footer p {
  font-size: 0.8rem;
  margin: 0;
}

/* Social media links styles */
.footer a {
  font-size: 1.5rem;
  color: #000000;
  /* Replace with your desired link color */
  text-decoration: none;
  margin-right: 1rem;
}

/* Add specific styles for the social media icons here */

/* ===================================================================== Header css ============================================================== */
/* Header container styles */
.header {
  max-width: 1200px;
  background-color: #d8f1e5;
  margin: 0 auto;
  margin-bottom: 10px;
  padding: 0.5rem;
  border-radius: 7px;
}

.terms_container {
  max-width: 1200px;
  background-color: white;
  margin: 0 auto;
  margin-bottom: 10px;
  padding: 0.5rem;
  border-radius: 7px;
  color: black;

}
.terms_container .terms_text{
  font-size: 20px !important;
}

.terms_container .terms_title{
  margin-top: 10px;
  font-weight: bold;
  font-size: 20px !important;
}

/* Logo link styles */
.header h3 a {
  font-size: 1.25rem;
  font-weight: 500;
  text-decoration: none;
  color: #000000;
}

/* Navigation links styles */
.header .navigation-links {
  display: flex;
  gap: 1rem;
  align-items: center;
}

/* Button styles */
.btn {
  font-size: 0.9rem;
  padding: 0.2rem 0.7rem;
  color: #ffffff;
  border: none;
  cursor: pointer;
  font-weight: 500;
}

.homeBtnstyle {
  font-size: 0.9rem;
  padding: 0.2rem 1rem;
  color: #ffffff;
  background-color: #29a56c;
  border: none;
  cursor: pointer;
  font-weight: 500;
  border: 1px solid #29a56c;
  border-radius: 4px;
}

.homeBtnstyle:hover {
  color: #29a56c;
  background-color: transparent;
}

/* Icon styles */
.icon {
  font-size: 0.9rem;
  margin-right: 0.5rem;
}

.navigation-links :nth-child(3).icon {
  font-size: 0.5rem;
}

@media screen and (min-width: 1530px) {
  .header {
    padding: 0.2rem 0.5rem;
  }
}

/* navbar.css */

/* ================================================================== Hero Slider ========================================= */
.Sliderhero {
  width: 100%;
}

.hero-content {
  width: 100%;
  height: 100%;
}

.hero-content video {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

/* ======================================================== Servie Nav ========================================================= */
.serviceNavbar {
  background-color: #d8f1e5;
  max-width: 1200px;
  margin: 7px auto;
  border-radius: 7px;
  padding-left: 10px;
}

.navitem {
  color: #000000;
  padding: 3px 11px;
  border-radius: 3px;
  font-size: 17px;
}

.dropdownitem {
  color: #000000;
}

.navitem:hover {
  color: #29a56c;
  background: #f4fff71d;
}

.dropdownMenu {
  background-color: #d8f1e5;
  backdrop-filter: blur(10px);
  top: 43px;
  left: 80px;
  border-radius: 5px;
  color: #000000;
  width: 150px;
  display: flex;
  flex-direction: column;
}

.dropdownIcon {
  font-size: 17px;
  color: #909090;
  margin-left: 3px;
}

.dropnavitem {
  color: rgb(0, 0, 0);
  padding: 3px 11px;
  border-radius: 3px;
  margin: 1px 0;
}

.dropnavitem:hover {
  color: #5533e0;
  background: #55555517;
}

/* ===========================================  Service Items =========================================== */
.sonodContainer {
  max-width: 1200px;
  margin: 14px auto;
}

.sonodLeft {
  background: #ffffff;
  padding: 10px;
  border-radius: 10px;
}

.sonodBtn {
  background: #d8f1e5;
  border-radius: 7px;
  padding: 8px;
  text-align: center;
  color: #29a56c;
}

.allsonod {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 20px 0;
  gap: 20px;
}
.profile_allsonod {
  position: relative;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin: 20px 0;
  gap: 20px;
}

.sonodCard{
  cursor: pointer;
  position: relative;
}
.sonodCard img {
  background: #000;
  border-radius: 5px;
  
}

.sonodCard:hover img {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.printPositionIcon{
  position: absolute;
  right: 15px;
  top: 15px;

}





.sonodRight {
  background: #ffffff;
  padding: 7px;
  border-radius: 7px;
  margin-bottom: 10px;
}

.rightBtn {
  width: 100%;
  padding: 10px;
  color: #ffffff;
  display: block;
  margin: 10px 0;
  text-align: center;
  border-radius: 5px;
  font-size: 15px;
}

@media screen and (max-width: 768px) {
  .allsonod {
    grid-template-columns: repeat(3, 1fr);
    margin: 15px 0;
    gap: 15px;
  }
}

@media screen and (max-width: 658px) {
  .allsonod {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 425px) {
  .allsonod {
    grid-template-columns: repeat(1, 1fr);
  }
}