.body {
  width: 100%;
  height: fit-content;
  padding: 40px;
}

.container {
  width: 100%;
  height: fit-content;
  border: 3px solid black;
  overflow: hidden;
  padding: 3px;
  position: relative;
}

.second_container {
  border: 3px solid gray;
  border-radius: 5px;
  padding: 8px 20px;

}

.content_position {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}



/* table content handel  */

.table_infomation_container .table_infomation {
  display: flex;
  gap: 5px;
}

.table_infomation .table_infomationp1 {
  width: 30%;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.table_infomation .table_infomationp2 {
  width: 70%;
  font-size: 14px;

}


.mainText {
  font-size: 14px;
  text-align: justify;
}

@media print {
  body {
    height: 100vh;
  }
  .container {
    height: calc(100vh - 80px);
  }

  .content_position {
    height: calc(100vh - 114px)
  }
}




