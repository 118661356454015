.body_countReport {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}
.container_countReport {
    position: relative !important;
    margin: auto;
    padding: 2mm; /* Add padding for better readability */
    box-sizing: border-box;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
}
.container_countReport div{
    margin-top: 5px;
    border: 5px double;
    width: 200mm; /* A4 width */
    height: 290mm; /* A4 height */
}
.headerContainer_countReport {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}
.table_countReport {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}
.th_countReport, .td_countReport {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
}
.th_countReport {
    background-color: #f4f4f4;
    color: black;
    font-size: 20px;
}
.td_countReport {
    background-color: #fff;
}
@media print {
    .body_countReport {
        margin: 0;
        background: none;
    }
    .container_countReport{
        border: none;
        box-shadow: none;
    }
}