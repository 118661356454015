.gotLogo {
    height: 70px;
    width: 70px;
}

.main_header {
    text-align: center;
}

.header_content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    color: black;
    line-height: 1.4;
}

.header_content .unionmunicipality {
    font-size: 24px;
    font-weight: bold !important;
}

.sonoName {
    margin: 15px 0px;
    width: fit-content;
    background: #4473C5;
    font-size: 24px;
    padding: 4px 30px;
    border-radius: 10px;
    color: white;
    border: #4473C5 2px solid;
    font-weight: bold !important;
}

/* sonod container */
.sonodInfoContainer{
    margin-bottom: 30px;
}
.createIssueDate{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.createIssueDate p{
    font-size: 16px;
}
.sonoNo table{
    border: 1px solid #000;
    font-size: 16px;
}
.sonoNo table tr th{
    font-weight: bold;
}



/* .timeline {
    display: flex;
    justify-content: space-between;
    margin: 20px 0px;
    font-weight: 700;
}

 */




/*

.pdfHeader h2 {
    font-weight: 700;
    font-size: 22px;
}






 */